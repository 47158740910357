var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":_vm.cols,"sm":_vm.sm,"md":_vm.md,"lg":_vm.lg,"xl":_vm.xl}},[_c('div',{staticClass:"dataTable"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-5 transition-swing",class:("elevation-" + (hover ? 24 : 0)),attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-0 pt-4"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","sm":"4","md":"6","lg":"6","xl":"6"}},[_c('Title',{staticClass:"pl-3",attrs:{"weight":"regular","color":"black","titleSize":"h5"}},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{class:_vm.version == 'dense' ? 'pt-0' : 'pt-0' && 
					_vm.version == 'regular' ? 'pt-2' : 'pt-2',attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","single-expand":false,"expanded":_vm.expanded,"dense":_vm.version == 'dense',"show-expand":_vm.version != 'dense' && !_vm.noExpand},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
					var item = ref.item;
return [(_vm.version != 'dense' && _vm.imgs != false)?_c('v-row',[_c('v-img',{attrs:{"src":_vm.$functions.getImgLink(item.avatar, 50),"lazy-src":_vm.$functions.getImgLink(item.avatar, 50),"aspect-ratio":"1","max-width":_vm.version == 'dense' ? '0px' : '0px' && 
					_vm.version == 'regular' ? '35px' : '35px',"max-height":_vm.version == 'dense' ? '0px' : '0px' && 
					_vm.version == 'regular' ? '35px' : '35px'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":1,"color":"primary"}})],1)]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"item.pictures",fn:function(ref){
					var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"contain":"","src":_vm.$functions.getImgLink(item.imgLink, 50),"lazy-src":_vm.$functions.getImgLink(item.imgLink, 50),"aspect-ratio":"1","max-width":_vm.version == 'dense' ? '0px' : '0px' && 
					_vm.version == 'regular' ? '35px' : '35px',"max-height":_vm.version == 'dense' ? '0px' : '0px' && 
					_vm.version == 'regular' ? '35px' : '35px'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"width":1,"color":"primary"}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.customers",fn:function(ref){
					var item = ref.item;
return _vm._l((item.participantsData),function(customer){return _c('v-chip',{key:customer.id},[(customer.avatar)?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.$functions.getImgLink(customer.avatar, 50)}})],1):_vm._e(),_vm._v(" "+_vm._s(customer.name || customer.lastName.toUpperCase() + " " + customer.firstName)+" ")],1)})}},{key:"item.author",fn:function(ref){
					var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.lastName.toUpperCase())+" "+_vm._s(item.author.firstName)+" ")]}},{key:"item.due",fn:function(ref){
					var item = ref.item;
return [(item.due)?_c('span',[_vm._v(_vm._s(_vm.$functions.getDateFormat(item.due)))]):_vm._e()]}},{key:"item.creationDate",fn:function(ref){
					var item = ref.item;
return [(item.creationDate)?_c('span',[_vm._v(_vm._s(_vm.$functions.getDateFormat(item.creationDate)))]):_vm._e()]}},{key:"item.value",fn:function(ref){
					var item = ref.item;
return [(item.value)?_c('span',[_vm._v(_vm._s(Number(item.value).toLocaleString(undefined, { minimumFractionDigits: 2}))+"€")]):_vm._e()]}},{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
					var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":_vm.$cssGlobal.getStatus(_vm.$listUtils[_vm.locale.textScheme][item.status], _vm.locale.colorScheme)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"color":_vm.$cssGlobal.getStatus(_vm.$listUtils[_vm.locale.textScheme][item.status], _vm.locale.colorScheme),"width":_vm.version == 'dense' ? '2px' : '2px' && 
					_vm.version == 'regular' ? '15px' : '15px',"height":_vm.version == 'dense' ? '100%' : '100%' && 
					_vm.version == 'regular' ? '15px' : '15px'}},'v-card',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$listUtils[_vm.locale.textScheme][item.status]))])])]}},{key:"item.manage",fn:function(ref){
					var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","icon":"","to":{path: _vm.link + '/' + item.id}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1),(_vm.canDelete)?_c('v-btn',{attrs:{"text":"","icon":""}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog.delete = true, _vm.activeItem = item.id}}},[_vm._v(" mdi-delete")])],1):_vm._e()],1)]}},(_vm.version != 'dense')?{key:"expanded-item",fn:function(ref){
					var headers = ref.headers;
					var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',[_c('v-card-title',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.civility)+" "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)+" ")]),(item.value)?_c('v-card-title',[_vm._v(" "+_vm._s(item.value.toLocaleString(undefined, { minimumFractionDigits: 2}))+" ")]):_vm._e(),(_vm.type !== 'mails')?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.locale.single)+" n°"+_vm._s(item[_vm.locale.idN])+" ")]):_vm._e(),(_vm.address && item.address.street)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(item.address.street)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.postalCode)+" "+_vm._s(item.address.city)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.region)+" • "+_vm._s(item.address.country)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"text--primary"},[_c('p',{staticClass:"text-justify",staticStyle:{"width":"80%"}},[_c('span',[_vm._v(" "+_vm._s(item.bio)+" ")]),(_vm.type === 'mails')?[(item.message)?_c('span',{domProps:{"innerHTML":_vm._s(item.message.html)}}):_vm._e()]:_vm._e()],2)]),(item.response)?_c('v-card-text',{staticClass:"text--primary"},[_c('h5',{staticClass:"body-1"},[_vm._v("Commande de l'intelligence artificielle")]),_c('p',[_c('code',[_vm._v(_vm._s(item.command))])]),_c('v-spacer',{staticClass:"my-3"}),_c('h5',{staticClass:"body-1"},[_vm._v("Réponse de l'intelligence artificielle")]),_c('p',[_c('code',[_vm._v(_vm._s(item.response))])])],1):_vm._e()],1),(item.avatar)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"my-4",attrs:{"height":"150px","width":"150px","contain":"","src":_vm.$functions.getImgLink(item.avatar)}})],1):_vm._e()],1)],1)]}}:null],null,true)}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-card-title',{staticStyle:{"position":"absolute","bottom":"-5px","left":"0"}},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fade",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('v-card',{staticClass:"glassmorphism"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var menu = ref.on;
					var attrs = ref.attrs;
return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var tooltip = ref.on;
return [_c('v-col',_vm._g(_vm._b({attrs:{"cols":"12","md":"6"}},'v-col',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){_vm.version = 'dense'}}},[_vm._v(" dense")])],1)]}}],null,true)},[_c('span',[_vm._v("L'affichage comportera moins d'informations et retire le menu déroulant")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var tooltip = ref.on;
return [_c('v-col',_vm._g(_vm._b({attrs:{"cols":"12","md":"6"}},'v-col',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary"},on:{"click":function($event){_vm.version = 'regular'}}},[_vm._v("regular")])],1)]}}],null,true)},[_c('span',[_vm._v("L'affichage comportera plus d'informations et un menu déroulant")])])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var tooltip = ref.on;
return [_c('v-col',_vm._g(_vm._b({attrs:{"cols":"12"}},'v-col',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-checkbox',{attrs:{"label":'Afficher les images'},model:{value:(_vm.imgs),callback:function ($$v) {_vm.imgs=$$v},expression:"imgs"}})],1)]}}],null,true)},[_c('span',[_vm._v("Affiche les images de proil sur la version regular")])])]}}],null,true)})],1)],1)],1)],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"text-right mt-3"},[_vm._t("default")],2),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog.delete),callback:function ($$v) {_vm.$set(_vm.dialog, "delete", $$v)},expression:"dialog.delete"}},[_c('v-card',{staticClass:"glassmorphism",attrs:{"color":"transparent"}},[_c('ButtonTrigger',{attrs:{"title":"Voulez-vous supprimer cet élément ?","small":"","whiteText":"","noButton":_vm.$vuetify.breakpoint.smAndUp,"icon":"","content":""},nativeOn:{"click":function($event){_vm.dialog.delete = false}}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"bt-error",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirmation de suppression ")]),_c('v-card-text',[_c('p',[_vm._v("Après avoir cliqué sur \"Confirmer la suppression\", cet élément sera définitivement supprimé.")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"neon evidence mb-4",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(_vm.activeItem)}}},[_vm._v(" Confirmer la suppression ")]),_c('v-btn',{staticClass:"neon evidence mb-4 float-right",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"outlined":"","color":"success"},on:{"click":function($event){_vm.dialog.delete = false}}},[_vm._v(" Annuler ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }