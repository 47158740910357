<template>

	<v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
		<div class="dataTable">

			<v-hover>
				<template v-slot:default="{ hover }">
					<v-card outlined :class="`elevation-${hover ? 24 : 0}`" class="mt-5 transition-swing">
						<v-card-title class="pa-0 pt-4">

							<v-col cols="12" sm="4" md="6" lg="6" xl="6" class="pa-0 ma-0">
								<Title class="pl-3" weight="regular" color="black" titleSize="h5">{{ title }}</Title>
							</v-col>

							<v-spacer></v-spacer>

							<v-col cols="12" sm="8" md="6" lg="6" xl="6">
								<v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher" single-line>
								</v-text-field>
							</v-col>

						</v-card-title>


						<v-data-table calculate-widths :headers="headers" :items="items" :search="search"
							item-key="id" :single-expand="false" :expanded.sync="expanded"
							:dense="version == 'dense'" :show-expand="version != 'dense' && !noExpand" :class="
						version == 'dense' ? 'pt-0' : 'pt-0' && 
						version == 'regular' ? 'pt-2' : 'pt-2'">

							<template v-slot:[`item.avatar`]="{ item }">

								<v-row v-if="version != 'dense' && imgs != false">

									<v-img v-bind:src="$functions.getImgLink(item.avatar, 50)"
										:lazy-src="$functions.getImgLink(item.avatar, 50)"
										aspect-ratio="1" :max-width="
						version == 'dense' ? '0px' : '0px' && 
						version == 'regular' ? '35px' : '35px'" :max-height="
						version == 'dense' ? '0px' : '0px' && 
						version == 'regular' ? '35px' : '35px'">

										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate :size="20" :width="1"
													color="primary">
												</v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-row>

							</template>

							<template v-slot:[`item.pictures`]="{ item }">

								<v-row align="center" justify="center">
									<v-img contain v-bind:src="$functions.getImgLink(item.imgLink, 50)"
										:lazy-src="$functions.getImgLink(item.imgLink, 50)"
										aspect-ratio="1" :max-width="
						version == 'dense' ? '0px' : '0px' && 
						version == 'regular' ? '35px' : '35px'" :max-height="
						version == 'dense' ? '0px' : '0px' && 
						version == 'regular' ? '35px' : '35px'">
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate :size="50" :width="1"
													color="primary">
												</v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-row>

							</template>

							<template v-slot:[`item.customers`]="{ item }">

								<v-chip v-for="customer in item.participantsData" :key="customer.id">
									<v-avatar
										left
										v-if="customer.avatar"
									>
										<v-img contain :src="$functions.getImgLink(customer.avatar, 50)"></v-img>
									</v-avatar>
									{{ customer.name || customer.lastName.toUpperCase() + " " + customer.firstName }}
								</v-chip>

							</template>

							<template v-slot:[`item.author`]="{ item }">
								{{ item.author.lastName.toUpperCase() }} {{ item.author.firstName }}
							</template>

							<template v-slot:[`item.due`]="{ item }">

								<span v-if="item.due">{{ $functions.getDateFormat(item.due) }}</span>

							</template>

							<template v-slot:[`item.creationDate`]="{ item }">

								<span v-if="item.creationDate">{{ $functions.getDateFormat(item.creationDate) }}</span>

							</template>

							<template v-slot:[`item.value`]="{ item }">

								<span
									v-if="item.value">{{ Number(item.value).toLocaleString(undefined, { minimumFractionDigits: 2}) }}€</span>

							</template>

							<template v-slot:placeholder>

								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate color="primary"></v-progress-circular>
								</v-row>

							</template>

							<template v-slot:[`item.status`]="{ item }">

								<v-tooltip top
									:color="$cssGlobal.getStatus($listUtils[locale.textScheme][item.status], locale.colorScheme)">
									<template v-slot:activator="{ on, attrs }">
										<v-card v-bind="attrs" v-on="on"
											:color="$cssGlobal.getStatus($listUtils[locale.textScheme][item.status], locale.colorScheme)"
											:width="
						version == 'dense' ? '2px' : '2px' && 
						version == 'regular' ? '15px' : '15px'" :height="
						version == 'dense' ? '100%' : '100%' && 
						version == 'regular' ? '15px' : '15px'"></v-card>
									</template>
									<span>{{ $listUtils[locale.textScheme][item.status] }}</span>
								</v-tooltip>

							</template>

							<template v-slot:[`item.manage`]="{ item }">

								<div class="text-center">
									<v-btn text icon :to="{path: link + '/' + item.id}">
										<v-icon color="primary">mdi-eye</v-icon>
									</v-btn>

									<v-btn text icon v-if="canDelete">
										<v-icon color="error" @click="dialog.delete = true, activeItem = item.id">
											mdi-delete</v-icon>
									</v-btn>
								</div>

							</template>

							<template v-slot:expanded-item="{ headers, item }" v-if="version != 'dense'">

								<td :colspan="headers.length">
									<v-row>
										<v-col>

											<v-card-title>
												{{ item.name }}
												{{ item.civility }}
												{{ item.lastName }}
												{{ item.firstName }}
											</v-card-title>

											<v-card-title v-if="item.value">
												{{ item.value.toLocaleString(undefined, { minimumFractionDigits: 2}) }}
											</v-card-title>

											<v-card-subtitle v-if="type !== 'mails'">
												{{ locale.single }} n°{{ item[locale.idN] }}
											</v-card-subtitle>

											<v-card-subtitle v-if="address && item.address.street">
												{{ item.address.street }}
												<br>
												{{ item.address.postalCode }} {{ item.address.city }}
												<br>
												{{ item.address.region }} • {{ item.address.country }}
											</v-card-subtitle>

											<v-card-text class="text--primary">
												<p
													class="text-justify"
													style="width: 80%;"
												>
													<span>
														{{ item.bio }}
													</span>

													<template v-if="type === 'mails'">

														<span
															v-if="item.message"
															v-html="item.message.html"
														></span>

													</template>

												</p>
											</v-card-text>

											<v-card-text
												class="text--primary"
												v-if="item.response"
											>
												<h5 class="body-1">Commande de l'intelligence artificielle</h5>
												<p>
													<code>{{ item.command }}</code>
												</p>

												<v-spacer class="my-3"></v-spacer>

												<h5 class="body-1">Réponse de l'intelligence artificielle</h5>
												<p>
													<code>{{ item.response }}</code>
												</p>
											</v-card-text>

										</v-col>
										<v-col
											cols="auto"
											v-if="item.avatar"
										>
											<v-img
												height="150px"
												width="150px"
												contain
												v-bind:src="$functions.getImgLink(item.avatar)"
												class="my-4"
											></v-img>
										</v-col>
									</v-row>
								</td>
							</template>
						</v-data-table>
						<v-card-title style="position: absolute; bottom: -5px; left: 0;"
							v-if="$vuetify.breakpoint.smAndUp">
							<v-menu offset-x>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon color="primary" class="fade">
										<v-icon>mdi-cog</v-icon>
									</v-btn>
								</template>
								<v-card class="glassmorphism">
									<v-col cols="12">
										<v-menu>
											<template v-slot:activator="{ on: menu, attrs }">
												<v-col cols="12">
													<v-row>
														<v-tooltip bottom>
															<template v-slot:activator="{ on: tooltip }">
																<v-col cols="12" md="6" v-bind="attrs"
																	v-on="{ ...tooltip, ...menu }">
																	<v-btn outlined block color="primary"
																		@click="version = 'dense'">
																		dense</v-btn>
																</v-col>
															</template>
															<span>L'affichage comportera moins d'informations et retire
																le menu déroulant</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on: tooltip }">
																<v-col cols="12" md="6" v-bind="attrs"
																	v-on="{ ...tooltip, ...menu }">
																	<v-btn outlined block color="primary"
																		@click="version = 'regular'">regular</v-btn>
																</v-col>
															</template>
															<span>L'affichage comportera plus d'informations et un menu
																déroulant</span>
														</v-tooltip>
													</v-row>
												</v-col>
												<v-tooltip bottom>
													<template v-slot:activator="{ on: tooltip }">
														<v-col cols="12" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
															<v-checkbox v-model="imgs" :label="'Afficher les images'">
															</v-checkbox>
														</v-col>
													</template>
													<span>Affiche les images de proil sur la version regular</span>
												</v-tooltip>
											</template>
										</v-menu>
									</v-col>
								</v-card>
							</v-menu>
						</v-card-title>
					</v-card>
				</template>
			</v-hover>

			<div class="text-right mt-3">
				<slot></slot>
			</div>

			<v-dialog v-model="dialog.delete" width="600">
				<v-card class="glassmorphism" color="transparent">
					<ButtonTrigger title="Voulez-vous supprimer cet élément ?" small whiteText
						:noButton="$vuetify.breakpoint.smAndUp" icon content="" @click.native="dialog.delete = false" />
					<v-col cols="12">
						<v-card class="bt-error" outlined>
							<v-card-title class="headline">
								Confirmation de suppression
							</v-card-title>
							<v-card-text>
								<p>Après avoir cliqué sur "Confirmer la suppression", cet élément sera définitivement
									supprimé.</p>
							</v-card-text>
							<v-col cols="12">
								<v-spacer></v-spacer>
								<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error"
									class="neon evidence mb-4" @click="deleteItem(activeItem)">
									Confirmer la suppression
								</v-btn>
								<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
									class="neon evidence mb-4 float-right" @click="dialog.delete = false">
									Annuler
								</v-btn>
							</v-col>
						</v-card>
					</v-col>
				</v-card>
			</v-dialog>

		</div>

	</v-col>
</template>


<style scoped>
	.fade {
		opacity: 0.2;
	}

	.fade:hover {
		opacity: 1;
	}

</style>

<script>
	import Title from '@/components/graphics/title'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'


	export default {
		props: {
			id: String,

			type: String,

			title: String,

			cols: Number,
			sm: Number,
			md: Number,
			lg: Number,
			xl: Number,

			headers: Array,
			items: Array,

			link: String,

			address: Boolean,
			showExpand: Boolean,

			company: {
				type: String,
				default: null
			},

			canDelete: {
				type: Boolean,
				default: false
			},

			noExpand: {
				type: Boolean,
				default: false
			}
		},

		components: {
			Title,
			ButtonTrigger
		},

		data() {
			return {
				version: 'regular',
				imgs: true,

				expanded: [],

				search: '',

				attrs: '',
				on: '',

				locale: {},

				dialog: {
					delete: false
				},

				activeItem: ''
			}
		},

		methods: {
			deleteItem(id) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.company).collection(this.type).doc(id));

				this.dialog.delete = false;
			}
		},

		created() {
			switch (this.type) {
				case "users":
					this.locale = {
						single: 'Utilisateur',
						idN: 'nClient',
						colorScheme: 'default',
						textScheme: 'elementStatus'
					}
					break;

				case "customers":
					this.locale = {
						single: 'Client',
						idN: 'nClient',
						colorScheme: 'default',
						textScheme: 'elementStatus'
					}
					break;

				case "companies":
					this.locale = {
						single: 'Entreprise',
						idN: 'nCompany',
						colorScheme: 'default',
						textScheme: 'elementStatus'
					}
					break;

				case "products":
					this.locale = {
						single: 'Produit',
						colorScheme: 'product',
						textScheme: 'productStatus'
					}
					break;

				case "projects":
					this.locale = {
						single: 'Produit',
						colorScheme: 'default',
						textScheme: 'elementStatus'
					}
					break;

				case "mails":
					this.locale = {
						single: 'Mail',
						colorScheme: 'mail',
						textScheme: 'mailStatus'
					}
					break;

				default:
					this.locale = {
						single: 'Élément',
						idN: 'nElement',
						colorScheme: 'default',
						textScheme: 'elementStatus'
					}
					break;
			}
		}
	}

</script>
